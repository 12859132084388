<template>
    <div id="pagination" v-if="!isloading && totRecords > 0">
        <div v-if="showGoTo">
            <b>Go To:&nbsp;&nbsp;</b>
            <input type="number" name="" id="" style="width: 60px; 
            border: 1px solid rgb(0, 0, 0); border-radius: 10%;
            text-align: center;" min="1" :max="page_len" @keyup.enter="goTo($event.target.value)">
        </div>
        <div v-if="showPerPage">
            <b>Showing:</b>
            [<span v-for="p in page" v-bind:key="p" v-on:click="handlePerPage(p)" :class="perPageClass(p)">{{ p }}</span>]
            results {{ startRecord }} to {{ parseInt(currentPage) === parseInt(lastPage) ? totRecords : lastRecord }}
            of {{ totRecords }}
            records
        </div>
        <div v-if="showPerPage == false">
            <b>Showing:</b>
            results {{ startRecord }} to {{ parseInt(currentPage) === parseInt(lastPage) ? totRecords : lastRecord }}
            of {{ totRecords }}
            records
        </div>
        <div v-on:click="paginate(0)" :class="isCurrentPageClass">[First]</div>
        <div v-on:click="paginate(-1)" :class="isCurrentPageClass">[Prev {{ perpage }}]</div>
        <div>
            [<span v-for="(v, i) in getPages" v-bind:key="i" v-on:click="getDataByPage(v)" v-bind:class="activeText(v)">{{ v
            }}</span>]
        </div>
        <div v-on:click="paginate(+1)" :class="getLastPageClass">
            [Next {{ perpage }}]
        </div>
        <div v-on:click="last()" :class="getLastPageClass">[Last]</div>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    data: () => ({
        page: [10, 20, 30, 40, 50],
    }),
    computed: {
        page_len() {
            return Math.ceil(this.totRecords / this.perpage)
        }
    },
    props: [
        'isloading',
        'startRecord',
        'currentPage',
        'lastPage',
        'lastRecord',
        'totRecords',
        'isCurrentPageClass',
        'perpage',
        'getLastPageClass',
        'totPage',
        'getPages',
        'showPerPage',
        'showGoTo'
    ],
    methods: {
        handlePerPage: function (p) {
            this.$emit('handlePerPage', p)
        },
        perPageClass: function (p) {
            return this.perpage === p ? "" : "notactivespan"
        },
        activeText: function (n) {
            return parseInt(n) === parseInt(this.currentPage) ? '' : 'notactivespan'
        },
        goTo: function (page) {
            if (page > this.page_len || page < 1) {
                this.$swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Page does not exist",
                });

            } else {
                this.getDataByPage(parseInt(page))
            }
        },
        getDataByPage: function (page) {
            this.$emit('getDataByPage', page)
        },
        paginate: function (n) {
            this.$emit('paginate', n)
        },
        last: function () {
            this.$emit('last', '')
        },
    }
}
</script>

<style lang="sass" scoped>
    @import "../../../sass/pagination.sass"
</style>