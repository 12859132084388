<template>
    <div id="datePicker">
        <span style="color:red">{{errormsg}}</span>
        <v-container  id="searchForm__container">
            <!-- <v-expand-transition> -->
                <!-- <div v-show="expand" no-gutters> -->
                    <v-row>
                       
                         <v-col>
                            <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                no-title
                                v-model="sdate"
                                label="Select Start Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="sdate"
                            @input="menu2 = false"
                            no-title scrollable 
                            ></v-date-picker>
                        </v-menu>
                        </v-col>

                        <v-col >
                            <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                no-title
                                v-model="edate"
                                label="Select End Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="edate"
                            @input="menu3 = false"
                            no-title scrollable 
                            ></v-date-picker>
                        </v-menu>
                        </v-col>
                        
                       
                         
                    </v-row>
           
                               
        </v-container>
    </div>
</template>

<script>

export default {
    name: 'datePicker',
    data: () => ({
        
        client_endpoint:'',
        errorMessage:{
            "date_er_message":"Please select start date and end date"
        },
        
        sdate:'',    
       
          modal: false,
          endatemodel:false,
          errormsg:'',
          edate:'',
          sdate:'',
          menu2: false,
          menu3: false,
          
    }),
    
    props: [
        'formType',
        'stateName',
    ],
    mounted(){
        let view = this        
        this.sdate = ""
        this.edate = ""

    },
    computed: {
        
    },
    methods: {
         formatDate(date) {
      var [date, month, year] = date.toLocaleDateString().split('/')
      return `${year}-${month}-${date}`;
    },
        
        searchData() {
            var view = this
            let d1 = new Date(this.sdate)
            let d2 = new Date(this.edate)
            
           
            if (d1.getTime() > d2.getTime())
            {
                alert("Start Date should not be greater then end date ")

            }
            else{
                let searchobj = {}
                searchobj["sdate"] = this.sdate
                searchobj["edate"] = this.edate
              
                view.$emit('search',searchobj)
            }
            
        },
        reset: function() {
            var view = this
            // this.sdate = ''
            // this.edate = ''
            view.select = ''
            view.$emit('reset')
        },
        getDate: function(dt){
            
            console.log('testing'+dt)
            this.$refs.dialog.save(dt)
            //  view.$emit('date',dt)

        },
        
    },
    watch: {
        
    }
}
</script>


<style scoped lang="sass">
@import './../../../sass/searchform'
#searchForm__container div.col
  padding-top: 1px
  padding-bottom: 1px
#searchForm__container div.row
  margin-top:0px
#searchForm__container
  padding-top:0px
</style>
