<template>
  <div class="container">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>

<div class="text-left">
    <v-container fluid>
        <v-row >
         <v-col cols="4" >
           
          <v-text-field
                    label="Search"
                    ref="input_query"
                    
                    v-model="searchQuery"
                />
             
          </v-col>
          <v-col cols="5">
            <datePicker ref="datepicker"/>    
          </v-col>
          <v-col cols="3" >
            <v-btn small color="primary my-4"  @click.prevent="search">
              Search
        </v-btn>
        <v-btn small  color="default" class="mx-2 my-4" @click.prevent="clearSearch">
              Clear
        </v-btn>
         </v-col>
       </v-row>
       <v-row no-gutters>
                <v-col :cols="6">
                    <v-row align="center" no-gutters>
                        <v-col cols="2" no-gutters>
                            <v-checkbox label="ID" ref="fontsize2" v-model="sesrchById"/>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox label="Duplicate File" ref="fontsize3" v-model="searchBySubject"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
     </v-container>
</div>
        <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <v-data-table
              :headers="headers"   
              item-key="id"           
              :page.sync="currentPage"
              :items="dmduplicates"
              :options.sync="options"
              :server-items-length="totRecords"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item.custom_id="{ item }">
                <v-list-item-content style="cursor:pointer" @click.prevent="showDuplicates(item)">{{item.id}}</v-list-item-content>
              </template>
              <!-- <template v-slot:item.product_id="{ item }">
                <v-list-item-content class="deduplicate_item__2" style="cursor:pointer"  @click.prevent="showDuplicates(item)">{{ellespingSubject(item.product_id)}}</v-list-item-content>
              </template> -->
              <template v-slot:item.duplicate_filename="{ item }">
                <v-list-item-content class="deduplicate_item__3" >{{item.duplicate_filename}}</v-list-item-content>
              </template>
              <template v-slot:item.total_duplicates="{ item }">
                <p>{{item.totalduplicate}}</p>
              </template>

              <template v-slot:item.compare="{ item }">
                <v-list-item-content class="deduplicate_item__3" >

                  <a :href="'compare_pdf/'+item.id" target="_blank">compare</a>
                </v-list-item-content>
              </template>

            </v-data-table>
            <div class="text-center pt-4 pb-4">
             
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>

            <div></div>
          </div>
      </v-col>
    </v-row>
    <!-- v-data-table-ends -->
     <v-card v-if="show_duplicate">
      <DmduplicateCheck  @hidePopup="hidePopup" :show_duplicate="show_duplicate" :dmdupObject="dmdupObject" />
    </v-card>

  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DmduplicateCheck from './DmduplicateCheck'
import datePicker from './component/datepicker.vue';

export default {
  components: { Index, Buttons, Loading, Pagination, DmduplicateCheck ,datePicker},
  data() {
    return {
      //////table data starts here////
      options: {},
      isLoading: true,      
      fullPage: true,
      sort: "",    
      headers: [
        {
          text: "ID",
          value: "custom_id",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        
        {
          text: "Duplicate File",
          value: "duplicate_filename",
          sortable: false,
          width: "350px",
          class: "v-data-table-header",
        },
         
        {
          text: "Date",
          value: "insert_update_date",
          sortable: false,
           width: "160px",
          class: "v-data-table-header",
        },
        {
          text: "Total Duplicates",
          value: "total_duplicates",
          width: "120px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Compare",
          value: "compare",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      checkbox: false,
      affinity: [],
      checkbox1: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      query: "",
      checked: false,
      sdate:'',
      edate:'',
      //pagination code
      perpage: 50,
      // totRecords: null,
      page: 1,
      isLoading: false,
      // fullPage: true,
      showPerPage: false,
      subject_length: 125,
      ///
      orders: [
                    {'key': 'id', 'val': true},
                    {'key': 'insert_update_date', 'val': true}
                ],
                enableSorting: false,
                show_duplicate: false,
                dmdupObject: false
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    perpage: function () {
       return this.$store.getters['Dmduplicate/getPerPage']
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    totRecords: function () {
            var store = this.$store
            return store.getters['Dmduplicate/getTotalDmduplicates'] == '' ? 0 : store.getters['Dmduplicate/getTotalDmduplicates']
        },
    currentPage: function () {
      return this.$store.getters['Dmduplicate/getCurrentPage']
    },
    lastPage: function () {
      return this.$store.getters['Dmduplicate/getLastPage']
    },
    ///////
    // total_count: function () {
    //         return this.dedup.duplicates.split(',').length
    //     },
    dmduplicates: function () {
        return this.allDmduplicates()
    },
    searchQuery: {
            get:function() {
                return this.$store.getters['Dmduplicate/getSearchQuery']
            },
            set: function(o) {
                this.$store.dispatch('Dmduplicate/setSearchQuery', o)
            }
        },
        sesrchById: {
            get:function() {
                return this.$store.getters['Dmduplicate/getSearchById']
            },
            set: function(o) {
                this.$store.dispatch('Dmduplicate/setSetSearchBySubject', false)
                this.$store.dispatch('Dmduplicate/setSearchQuery', '')
                this.$store.dispatch('Dmduplicate/setSearchById', o)
            }
        },
        searchBySubject: {
            get:function() {
                return this.$store.getters['Dmduplicate/setSetSearchBySubject']
            },
            set: function(o) {
                this.$store.dispatch('Dmduplicate/setSearchById', false)
                this.$store.dispatch('Dmduplicate/setSearchQuery', '')
                this.$store.dispatch('Dmduplicate/setSetSearchBySubject', o)
            }
        }
  },
  methods: {
    dedupClass: function(i) {
        return parseInt(i)%2 === parseInt(0) ? 'grey lighten-2' : 'grey lighten-5'
    },
    showDuplicates: function(dmdupObject){
      this.dmdupObject = dmdupObject
      this.show_duplicate = !this.show_duplicate
    },
    hidePopup: function(flag){
      if(flag){
        this.show_duplicate = false
      }
    },
    fetchDuplicates: function(flag){
      if(flag){
        this.show_duplicate = false
      }
    },
      ellespingSubject: function(subject){
        return (subject != null)?((subject.length > this.subject_length)?`${subject.substring(0, this.subject_length)}...`:subject):'';
      },
    search: function() {
            this.getMuids()
        },
        clearSearch: function() {
            var view = this
            this.$refs.datepicker.sdate=''
            this.$refs.datepicker.edate=''
            view.$store.dispatch('Dmduplicate/setIsFromSearch', false).then(function () {
                return view.$store.dispatch('Dmduplicate/setSearchQuery', '')
            }).then(function() {
                return view.$store.dispatch('Dmduplicate/setSearchById', true)
            }).then(function() {
                return view.$store.dispatch('Dmduplicate/setSetSearchBySubject', false)
            }).then(function() {
                return view.$store.dispatch('Dmduplicate/setPerPage', view.perpage)
            }).then(function() {
                return view.$store.dispatch('Dmduplicate/setPage', 1)
            }).then(function() {
                view.getMuids()
            })
        },
    allDmduplicates() {
      
                if (this.enableSorting) {
                    this.enableSorting = false
                    return this.$store.getters['Dmduplicate/getResponsePayload']
                } else {
                  console.log(this.$store.getters['Dmduplicate/getResponsePayload'])
                 
                    return this.$store.getters['Dmduplicate/getResponsePayload']
                }
            },
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    //pagination methods
    getDataByPage(page) {
      let view = this
      view.$store.dispatch("Dmduplicate/setPage", page).then(function () {
                    return view.$store.dispatch("Dmduplicate/setIsLoading", true);
                }).then(function () {
                    view.getMuids();
                }).catch(function (e) {
                    console.log(e);
                });
    },
    handlePerPage(p) {
      let view = this
      view.$store.dispatch("Dmduplicate/setPerPage", p).then(function () {
                    return view.$store.dispatch("Dmduplicate/setPage", parseInt(1));
                }).then(function () {
                  return view.$store.dispatch("Dmduplicate/setIsLoading", true);
                }).then(function () {
                  view.getMuids();
                }).catch(function (e) {
                  console.log(e);
                });
    },
    paginate(n) {
      console.log(n);
      let view= this
      if (parseInt(n) === parseInt(0) && parseInt(this.currentPage) === parseInt(1)) {
                return;
            }
            if (parseInt(n) === parseInt(-1)) {
                if (parseInt(this.currentPage) <= parseInt(1)) {
                    return;
                }
            }
            if (parseInt(n) === parseInt(1)) {
                if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
                    return;
                }
            }
        let myPromise = new Promise(function (resolve, reject) {
                    resolve(view.$store.dispatch("Dmduplicate/setPage", n === 0 ? 1 : parseInt(view.currentPage) + parseInt(n)));
                    reject("Error: on set page");
                });
                myPromise.then(function () {
                    view.$store.getters['Dmduplicate/getIsFromSearch'] ? view.getMuids() : view.getMuids();
                }).catch(function (e) {
                    console.log(e);
                });
    },
    last() {
      let view = this
      if (parseInt(view.lastPage) === parseInt(view.currentPage)) {
                    return;
                }
                view.$store.dispatch("Dmduplicate/setPage", view.lastPage).then(function () {
                    view.$store.dispatch("Dmduplicate/setIsLoading", true);
                }).then(function () {
                    view.getMuids();
                }).catch(function (e) {
                    console.log(e);
                });
    },
    /////
    getMuids123() {    
      let view = this
      view.$store.dispatch('Dmduplicate/setStartTime', new Date().getTime()).then(function () {
            view.isLoading = true
            return view.$store.dispatch('Dmduplicate/setIsLoading', true)
        }).then(function () {
            return view.$store.dispatch('Dmduplicate/setResponsePayload', [])
        }).then(function () {
            view.$fetch({ requiresAuth: true, operation : 'add' , vueScope: view })

            .post(view.$url('DMDUPLICATES'), JSON.stringify(view.$store.getters['Dmduplicate/getRequestPayload']), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(function (res) {
                let response =  res.data
               
                view.isLoading = false
                view.$store.dispatch('Dmduplicate/setEndTime', new Date().getTime())
                view.$store.dispatch('Dmduplicate/setIsLoading', false)
                typeof response.payload !== 'undefined' ? view.$store.dispatch('Dmduplicate/setResponsePayload', response.payload) : ''
                typeof response.hasNext !== 'undefined' ? view.$store.dispatch('Dmduplicate/setHasNext', response.hasNext) : ''
                typeof response.totalDedups !== 'undefined' ? view.$store.dispatch('Dmduplicate/setTotalDmduplicates', response.totalDedups) : ''
                typeof response.lastPage !== 'undefined' ? view.$store.dispatch('Dmduplicate/setLastPage', response.lastPage) : ''
            }).catch(function (e) {
                console.log(e)
            })
        })
    },
    getMuids() {
       let view=this
        view.isLoading = true
        
        let sdate = this.$refs.datepicker.sdate
        let edate = this.$refs.datepicker.edate
      
        view.$store.dispatch('Dmduplicate/setIsFromSearch', true).then(function () {
            return view.$store.dispatch('Dmduplicate/setStartTime', new Date().getTime())
        }).then(function () {
            return view.$store.dispatch('Dmduplicate/setIsLoading', true)
        }).then(function () {
            return view.$store.dispatch('Dmduplicate/setResponsePayload', [])
        }).then(function () {
            view.$fetch({ requiresAuth: true, operation : 'add' , vueScope: view })
            
            .post(view.$url('DMDUPLICATES_SEARCH'), JSON.stringify({
                    search: view.$store.getters['Dmduplicate/gerSearchPayload'],
                    startdate:sdate,
                    enddate:edate,
                    payload: view.$store.getters['Dmduplicate/getRequestPayload'],
                }), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(function (res) {
              
                view.isLoading = false
                let response = res.data
                view.$store.dispatch('Dmduplicate/setEndTime', new Date().getTime())
                view.$store.dispatch('Dmduplicate/setIsLoading', false)
                typeof response.payload !== 'undefined' && Array.isArray(response.payload) ? view.$store.dispatch('Dmduplicate/setResponsePayload', response.payload) : []
                typeof response.hasNext !== 'undefined' ? view.$store.dispatch('Dmduplicate/setHasNext', response.hasNext) : ''
                typeof response.totalDedups !== 'undefined' ? view.$store.dispatch('Dmduplicate/setTotalDmduplicates', response.totalDedups) : ''
                typeof response.lastPage !== 'undefined' ? view.$store.dispatch('Dmduplicate/setLastPage', response.lastPage) : ''
            }).catch(function (e) {
                console.log(e)
            })
        })
    }   
  },
 mounted() {
  //  alert('active module==='+this.$store.state.Rawdata.current_active_module)
    this.getMuids();
 },

  watch: {    
  '$store.state.Rawdata.current_active_module': function(o) {
      if(o){
            this.getMuids();
      }
    },
 },
};
</script>
